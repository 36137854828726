import { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { dispatch } from 'store';
import { setSucursales } from 'store/slices/negocio';
import { removeToken } from 'utils/axios';
import PUBLIC_ROUTES from 'helpers/publicRoutes';

const negocioContext = createContext();

export function NegocioProvider({ children }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [negocio, setNegocio] = useState(() => {
        const savedNegocio = localStorage.getItem('negocio');
        return savedNegocio ? JSON.parse(savedNegocio) : null;
    });

    const setSessionData = (data) => {
        setNegocio(data);
        localStorage.setItem('negocio', JSON.stringify(data));
    };

    const logout = async () => {
        try {
            setNegocio(null);
            removeToken();
            localStorage.clear();
            navigate('login');
        } catch (error) {
            console.log('Ocurrió un error al cerrar sesión. Intente nuevamente');
        }
    };

    useEffect(() => {
        const url = location.pathname;

        if (!PUBLIC_ROUTES.some((route) => route.url.includes(url))) {
            if (negocio) {
                localStorage.setItem('negocio', JSON.stringify(negocio));
                dispatch(setSucursales(negocio.sucursales));
            } else {
                logout();
            }
        }
    }, [negocio]);

    const data = {
        negocio,
        setSessionData,
        logout
    };

    return <negocioContext.Provider value={data}>{children}</negocioContext.Provider>;
}

export const useNegocioContext = () => useContext(negocioContext);
