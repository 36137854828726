export const updateFavicon = (faviconUrl) => {
    let link = document.querySelector("link[rel='icon']") || document.createElement('link');
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        link.type = 'image/png';
        link.href = faviconUrl;
        document.head.appendChild(link);
    }
    link.href = faviconUrl;
};

export const updateThemeColor = (color) => {
    let meta = document.querySelector('meta[name="theme-color"]');
    if (!meta) {
        meta = document.createElement('meta');
        meta.name = 'theme-color';
        document.head.appendChild(meta);
    }
    meta.content = color;
};
