// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    info: {},
    configAvanzada: {},
    sucursales: [],
    nuevaSucursal: {}, // Almacena los datos de una nueva sucursal antes de ser guardada
    cargando: false // Indica si hay una operación de carga en curso
};

const slice = createSlice({
    name: 'negocio',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET INFO NEGOCIO
        getInfoSuccess(state, action) {
            state.info = action.payload.negocio;
            state.configAvanzada = action.payload.negocio_configuracion_avanzada;
        },

        getInfoAvanzada(state, action) {
            state.configAvanzada = action.payload;
        },

        // CHANGE INFO NEGOCIO
        changeInfoNegocio(state) {
            state.error = null;
        },

        // LIMPIAR INFO NEGOCIO
        limpiarInfoNegocioSuccess(state) {
            state.info = {};
        },

        // GET SUCURSALES
        getSucursalesSuccess(state, action) {
            state.sucursales = action.payload;
        },

        // LIMPIAR SUCURSALES
        limpiarSucursalesSuccess(state) {
            state.sucursales = [];
        },

        // AGREGAR SUCURSAL
        postSucursalSuccess(state, action) {
            state.nuevaSucursal = action.payload;
        },

        // SET CARGANDO = TRUE
        setCargandoSuccess(state) {
            state.cargando = true;
        },

        // SET CARGANDO = FALSE
        unsetCargandoSuccess(state) {
            state.cargando = false;
        }
    }
});

// Reducer
export default slice.reducer;

// -----------------------------------------------------
export const setCargando = () => () => {
    dispatch(slice.actions.setCargandoSuccess());
};

export const unsetCargando = () => () => {
    dispatch(slice.actions.unsetCargandoSuccess());
};

export function getInfoNegocio() {
    dispatch(setCargando());
    return async () => {
        try {
            const response = await axios.get('/getNegocio');
            dispatch(slice.actions.getInfoSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
        dispatch(unsetCargando());
    };
}

export function postInfoNegocio(info) {
    return async () => {
        try {
            const response = await axios.post('/editarNegocio', info);

            dispatch(slice.actions.changeInfoNegocio());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editConfigAvanzada(info) {
    return async () => {
        try {
            dispatch(setCargando());
            const response = await axios.post('/editarConfAvanzada', info);
            dispatch(slice.actions.getInfoAvanzada(response.data.config));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(unsetCargando());
        }
    };
}

export function limpiarInfoNegocio() {
    return async () => {
        try {
            dispatch(slice.actions.limpiarInfoNegocioSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getSucursales() {
    return async () => {
        try {
            const response = await axios.get('/getSucursalesNegocio');
            dispatch(slice.actions.getSucursalesSuccess(response.data.data.sucursales));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setSucursales(sucursales) {
    return async () => {
        try {
            dispatch(slice.actions.getSucursalesSuccess(sucursales));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function limpiarSucursales() {
    return async () => {
        try {
            dispatch(slice.actions.limpiarSucursalesSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function agregarSucursal(info) {
    return async () => {
        try {
            const response = await axios.post('/addSucursal', info);

            dispatch(slice.actions.postSucursalSuccess(response.data.data.sucursal));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
