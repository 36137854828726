/* eslint-disable prettier/prettier */
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
// import SearchSection from './SearchSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import SelectSucursal from './SelectSucursal';
import TiempoDeEspera from './TiempoDeEspera';
import TiempoTakeAway from './TiempoTakeAway';

// import LocalizationSection from './LocalizationSection';

import NotificationSection from './NotificationSection';
import SwitchActividad from './SwitchActividad';
import { useDispatch, useSelector } from 'store';
import { getNotifications, openDrawer } from 'store/slices/menu';
import io from 'socket.io-client';
import React, { useEffect, useState } from 'react';
// assets
import { IconMenu2 } from '@tabler/icons';
import { getPedidos } from 'store/slices/pedidos';
import { getInfoNegocio } from 'store/slices/negocio';
import SwitchDelivery from './SwitchDelivery';
import { TIENDA_HANDY } from 'config';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SwitchTakeAway from './SwitchTakeAway';
import { getSucursalId } from 'utils/axios';

// import notification from 'assets/sounds/notification.mp3';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //
const Header = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const isLocalEnv = process.env.NODE_ENV === 'development'; // o cualquier otra lógica para determinar el entorno local
    let socket;
    const notificationAudio = new Audio('https://storage.googleapis.com/tds-img/public/notification-new.mp3');
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (!isLocalEnv) {
        const URL = 'https://socket-notificaciones-ev7ccfvftq-uc.a.run.app/';
        socket = io.connect(URL, { secure: true, reconnect: true, rejectUnauthorized: false });
    }

    const playAudio = (audio) => {
        const audioPromise = audio.play();
        if (audioPromise !== undefined) {
            audioPromise
                .then((_) => {
                    // autoplay started
                })
                .catch((err) => {
                    // catch dom exception
                    console.info(err);
                });
        }
    };

    useEffect(() => {
        if (!isLocalEnv && socket) {
            socket.on('connect', () => {
                // setConection(socket.id);
                socket.emit('storeClientInfo', { sucursal_id: getSucursalId() });
            });

            socket.on('enviarNotificacion', (data) => {
                playAudio(notificationAudio);
                dispatch(getNotifications());
                dispatch(getPedidos());
            });
        }

        // dispatch(getNotifications());
    }, [dispatch, socket]);

    useEffect(() => {
        dispatch(getInfoNegocio());
    }, []);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    alignItems: 'center',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1, order: TIENDA_HANDY ? 2 : 1 }}>
                    <LogoSection />
                </Box>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        order: TIENDA_HANDY ? 1 : 2,
                        marginRight: TIENDA_HANDY ? 2 : 0,
                        overflow: 'hidden',
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                        color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                        '&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                        }
                    }}
                    onClick={() => dispatch(openDrawer(!drawerOpen))}
                    color="inherit"
                >
                    <IconMenu2 stroke={1.5} size="1.3rem" />
                </Avatar>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            <TiempoTakeAway />
            <TiempoDeEspera />
            {/* notification & profile */}
            <NotificationSection />
            <>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <audio id="notification" src="assets/sounds/notification" muted="muted" />
            </>
            <SelectSucursal />
            <div>
                <Tooltip title="Estados de la Tienda" placement="top">
                    <IconButton onClick={handleClick}>
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem>
                        <SwitchActividad />
                    </MenuItem>
                    <MenuItem>
                        <SwitchDelivery />
                    </MenuItem>
                    <MenuItem>
                        <SwitchTakeAway />
                    </MenuItem>
                </Menu>
            </div>
            <ProfileSection />
            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </>
    );
};

export default Header;
