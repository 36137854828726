import { useEffect } from 'react';
// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

// auth provider
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
import { NegocioProvider } from 'contexts/NegocioContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

import { Helmet } from 'react-helmet';
import { updateFavicon } from 'utils/themeUtils';
import { TIENDA_HANDY } from 'config';

const faviconHandyDark = '/handy-favicon-dark.png';
const faviconHandyLight = '/handy-favicon-light.png';
const faviconRhino = '/favicon.png';
// ==============================|| APP ||============================== //

const App = () => {
    useEffect(() => {
        const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');
        const updateColors = () => {
            if (TIENDA_HANDY) {
                if (matchMedia.matches) {
                    updateFavicon(faviconHandyDark);
                } else {
                    updateFavicon(faviconHandyLight);
                }
            } else {
                updateFavicon(faviconRhino);
            }
        };

        updateColors();

        matchMedia.addEventListener('change', updateColors);

        return () => matchMedia.removeEventListener('change', updateColors);
    }, []);

    return (
        <ThemeCustomization>
            <Helmet>
                <title>{TIENDA_HANDY ? 'Handy - Dashboard' : 'Rhino - Dashboard Administración'}</title>
            </Helmet>
            {/* RTL layout */}
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <NegocioProvider>
                            <AuthProvider>
                                <>
                                    <Routes />
                                    <Snackbar />
                                </>
                            </AuthProvider>
                        </NegocioProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;
