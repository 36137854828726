// third-party
import { createSlice } from '@reduxjs/toolkit';
import { toInteger } from 'lodash';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    promociones: [],
    descuentos: [],
    cupones: [],
    tipo: '',
    customersFiltrados: [],
    status: 0,
    cargandoDescuento: false,
    loading: false
};

const slice = createSlice({
    name: 'descuento',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET PRMOCIONES
        getPromocionesSuccess(state, action) {
            state.promociones = action.payload;
        },

        // GET CUPONES
        getCuponesSuccess(state, action) {
            state.cupones = action.payload;
            state.descuentos = state.cupones;
            // state.descuentos = [...state.cupones, ...state.promociones];
        },

        getDescuentosSuccess(state, action) {
            state.descuentos = action.payload;
        },

        setTipo(state, action) {
            state.tipo = action.payload;
        },

        // SET CATEGORIES
        setStatusSuccess(state, action) {
            state.status = action.payload;
        },

        createStatusReset(state) {
            state.status = 0;
        },

        // SET CARGANDO = TRUE
        setCargandoSuccess(state) {
            state.cargandoDescuento = true;
        },

        // SET CARGANDO = FALSE
        unsetCargandoSuccess(state) {
            state.cargandoDescuento = false;
        },

        setLoadingSuccess(state) {
            state.loading = true;
        },

        unsetLoadingSuccess(state) {
            state.loading = false;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function setCargando() {
    return async () => {
        try {
            dispatch(slice.actions.setCargandoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function unsetCargando() {
    return async () => {
        try {
            dispatch(slice.actions.unsetCargandoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setLoading() {
    return async () => {
        try {
            dispatch(slice.actions.setLoadingSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function unsetLoading() {
    return async () => {
        try {
            dispatch(slice.actions.unsetLoadingSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPromociones(idNegocio) {
    return async () => {
        try {
            const response = await axios.get(`/getPromociones`, {
                params: {
                    id_negocio: idNegocio
                }
            });
            // console.log('promociones', response);
            dispatch(slice.actions.getPromocionesSuccess(response.data.promociones));
            dispatch(slice.actions.createStatusReset());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// get cupones
export function getCupones(idNegocio) {
    return async () => {
        try {
            dispatch(setCargando());
            const response = await axios.get(`/getCuponesNegocio/${idNegocio}`);
            dispatch(slice.actions.getCuponesSuccess(response.data.cupones));
            dispatch(slice.actions.setTipo('cupon'));
            dispatch(unsetCargando());
        } catch (error) {
            console.log('error', error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function filtrarDescuentos(tipo, promociones, cupones) {
    return async () => {
        // eslint-disable-next-line eqeqeq
        if (tipo == 'promocion') {
            // console.log('tipo', tipo);
            // get promociones
            dispatch(slice.actions.getDescuentosSuccess(promociones));
            dispatch(slice.actions.setTipo('promocion'));
        }
        // eslint-disable-next-line eqeqeq
        if (tipo == 'cupon') {
            // console.log('tipo', tipo);
            dispatch(slice.actions.getDescuentosSuccess(cupones));
            dispatch(slice.actions.setTipo('cupon'));
        }
    };
}

export function createDiscount(data) {
    return async () => {
        try {
            if (data.tipo === 'descuento') {
                // console.log('DATAAAA', data);
                const response = await axios.post(`/agregarPromocion`, data);
                console.log('response', response);
                dispatch(slice.actions.setStatusSuccess(response.status));
            }
            if (data.tipo === 'cupon') {
                // console.log('DATAAAA', data);
                const response = await axios.post(`/agregarCupon`, data);
                console.log('response', response);
                dispatch(slice.actions.setStatusSuccess(response.status));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // console.log('error', error);
        }
    };
}

export function updateDiscount(data) {
    dispatch(setCargando());
    return async () => {
        if (data.tipo === 'descuento') {
            const response = await axios.post(`/editarPromocion`, data);
            dispatch(slice.actions.setStatusSuccess(response.data.code));
        }
        if (data.tipo === 'cupon') {
            const response = await axios.post(`/editarCupon`, data);
            dispatch(slice.actions.setStatusSuccess(response.data.code));
        }
        dispatch(unsetCargando());
    };
}

// editar estado opcion
export function updateStatusDiscount(data) {
    dispatch(setLoading());
    return async () => {
        try {
            if (data.tipo === 'cupon') {
                const response = await axios.post(`/updateEstadoCupon`, data);
                dispatch(slice.actions.getDescuentosSuccess(response.data.cupones));
                dispatch(slice.actions.setTipo('cupon'));
            }
            if (data.tipo === 'promocion') {
                const response = await axios.post(`/updateEstadoPromocion`, data);
                dispatch(slice.actions.getDescuentosSuccess(response.data.promociones));
                dispatch(slice.actions.setTipo('promocion'));
            }
            dispatch(unsetLoading());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(unsetLoading());
        }
    };
}
